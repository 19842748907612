import Link from 'gatsby-link';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

const NotFoundPage = (): ReactElement => (
  <Layout>
    <Helmet title="404" />

    <h1>Sorry, that page does not exist</h1>
    <p>
      Return
      <Link to="/">home</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
